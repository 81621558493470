import React from 'react'
/*import home from '../assets/home.jpg'*/


const Home = () => {
  return (
    <div className='text-white h-screen bg-home-image bg-cover bg-center' id="home">
      <div className='max-w-[1140px] ml-[40%] md:ml-[60%]'>
                <div className='absolute top-[30%] w-full left-[20%] kecil:left-[50%] lg:left-[50%] laptop:left-[55%] max-w-[350px] lg:max-w-[600px] laptop:max-w-[600px] h-full flex flex-col text-white p-4'>
                    <h1 className='font-bold text-xl lg:text-4xl md:text-xl laptop:text-4xl font-merry'>PT. Lintech Energy Shipping</h1>
                    <p className='text-xs lg:text-xl laptop:text-xl'>
                    PT. Lintech Energy Shipping is presented to Indonesia’s ship 
                    and marine industry to delivery safest and highest quality of services (ISO9001) in ship building and marine works
                    </p>
                </div>
            </div>
      </div>
  )
}

export default Home