import React from 'react'

const Footer = () => {
  return (
    <footer class="w-full bg-[#489CB8]" id="footer">
    <div className='max-w-[1240px] flex justify-between items-center h-14 mx-auto px-1 text-white'>
    <span class="text-sm sm:text-center">© 2023 Lintech Energy Shipping. All Rights Reserved.
    </span>
    <ul class="flex flex-wrap items-center mt-1 text-sm sm:mt-0">
        <li>
            <a href="#" class="mr-4 hover:underline md:mr-6 "><a href="/#home">Home</a></a>
        </li>
        <li>
            <a href="#" class="hover:underline"><a href="/#contact">Contact Us</a></a>
        </li>
    </ul>
    </div>
</footer>
  )
}

export default Footer