import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const Navbar = () => {

    const [nav, setNav] = useState(false);
    const handleNav = () => {
        setNav(!nav);
    };

    return (
            <div className=' z-50 text-white sticky top-0 bg-[#4A636B] w-full'>
                <div className='max-w-[1240px] flex justify-between items-center h-14 mx-auto px-1 font-opensans'>
                <h1 className='w-full text-2xl font-bold text-white'>PT. Lintech Energy Shipping</h1>
                <ul className='hidden flex kecil:flex w-full'>
                    <li className='p-4'><a href="/#home">Home</a></li>
                    <li className='p-4 w-auto hover:text-blue-300'><a href="/#product">Product & Services</a></li>
                    <li className='p-4 w-auto hover:text-blue-300'><a href="/#gallery">Gallery</a></li>
                    <li className='p-4 hover:text-blue-300'><a href="/#facility">Facility</a></li>
                    <li className='p-4 hover:text-blue-300'><a href="/#contact">Contact Us</a></li>
                </ul>
                <div onClick={handleNav} className='block kecil:hidden'>
                    {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
                </div>
                <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
                <h1 className='w-full text-2xl font-bold text-[#00df9a] m-4'>PT. LES</h1>
                    <li className='p-4 border-b border-gray-600'><a href="/#home">Home</a></li>
                    <li className='p-4 border-b border-gray-600'><a href="/#product">Product & Services</a></li>
                    <li className='p-4 border-b border-gray-600'><a href="/#facility">Facility</a></li>
                    <li className='p-4'><a href="/#contact">Contact Us</a></li>
                </ul>
                </div>
        </div>
    )
}

export default Navbar