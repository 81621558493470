import React from 'react'
import Facilityimg from '../assets/facility1.jpg'

const Facility = () => {
  return (
    <div className='w-full bg-sky-800 py-16 px-4' id="facility">
      <div className='max-w-[1240px] mx-auto grid laptop:grid-cols-2 items-center justify-center laptop:justify-between text-stone-200'>
      <div className='about-info mb-5'>
                <h2 className='text-3xl font-bold mb-5 border-b-[5px] w-[360px] border-indigo-600 pb-2'>
                    Facility
                </h2>
                <p className='pb-5'>
                    Our shipyard is located at Tanjung Lintech (Lintech Cape), north coast East Java, Paciran, Lamongan, 
                    2 hours’ drive from Surabaya, 20 ha yard with 90mx280m slipway 4m deep waterfront, equipped with 1.5ha 
                    workshop with heavy and light machinery for automatic plate processing up to 1500ton per month capacity. 
                </p>
        </div>
        <div className='about-img'>
          <img
            src={Facilityimg}
            alt="/"
            className='laptop:w-[80%] laptop:ml-auto p-16 rounded-full'
          />
        </div>
      </div>
    </div>
  )
}

export default Facility