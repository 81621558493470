import React from 'react'
import gallery1 from '../assets/gallery1.jpeg'
import gallery2 from '../assets/gallery2.jpeg'
import gallery3 from '../assets/gallery3.jpeg'
import gallery4 from '../assets/gallery4.jpeg'
import gallery5 from '../assets/gallery5.jpeg'
import gallery6 from '../assets/gallery6.jpeg'
import gallery7 from '../assets/gallery7.jpeg'
import gallery8 from '../assets/gallery8.jpeg'
import gallery9 from '../assets/gallery9.jpeg'
import ship_loading1 from '../assets/ship_loading1.jpg'
import ship_loading2 from '../assets/ship_loading2.jpg'
import ship_loading3 from '../assets/ship_loading3.jpg'
import ship_loading4 from '../assets/ship_loading4.jpg'
import ship_loading5 from '../assets/ship_loading5.jpg'


const Gallery = () => {
  return (
    <section className='w-full bg-gray-50 py-16 px-4' id="gallery">
        <div className='container mx-auto grid md:grid-cols-2 items-center md:justify-between'>
            <div className='about-info mb-5'>
                <h2 className='text-3xl font-bold mb-5 border-b-[5px] w-[360px] border-indigo-600 pb-2'>
                    Gallery
                </h2>
            </div>
        </div>
        <div className='max-w-[1240px] mx-auto px-4 py-16 lg:py-24 relative bg-gray-50'>
            <div className='flex flex-col laptop:flex-row gap-2'>
                <div className='flex flex-1 flex-col gap-3'>
                    <div class='flex flex-2 flex-col'>
                        <img className='object-cover h-full' src={gallery1} alt='/' />
                    </div>
                    <div className='hidden laptop:flex flex-1 flex-row gap-2'>
                        <div className='flex flex-1 flex-col'>
                            <img className='object-cover h-full' src={gallery2} alt='/' />
                        </div>
                        <div className='hidden laptop:flex flex-2 flex-col'>
                            <img className='object-cover h-full' src={gallery3} alt='/' />
                        </div>
                    </div>
                    <div class='flex flex-1 flex-col'>
                        <img className='object-cover h-full' src={ship_loading1} alt='/' />
                    </div>
                    <div class='flex flex-1 flex-col'>
                        <img className='object-cover h-full' src={ship_loading2} alt='/' />
                    </div>
                </div>
                <div className='flex flex-1 flex-col gap-3'>
                    <div className='hidden laptop:flex flex-1 flex-row gap-2'>
                        <div className='flex flex-1 flex-col'>
                        <img className='object-cover h-full' src={gallery6} alt='/' />
                        </div>
                        <div className='hidden laptop:flex flex-1 flex-col'>
                            <img className='object-cover h-full' src={gallery5} alt='/' />
                        </div>
                    </div>
                    <div className='flex flex-1 flex-col'>
                            <img className='object-cover h-full' src={ship_loading5} alt='/' />
                    </div>
                </div>
                <div className='flex flex-1 flex-col gap-3'>
                    <div class='flex flex-1 flex-col'>
                        <img className='object-cover h-full' src={gallery7} alt='/' />
                    </div>
                    <div className='hidden laptop:flex flex-1 flex-row gap-2'>
                        <div className='flex flex-2 flex-col'>
                            <img className='object-cover h-full' src={gallery8} alt='/' />
                        </div>
                        <div className='hidden laptop:flex flex-1 flex-col'>
                            <img className='object-cover h-full' src={gallery5} alt='/' />
                        </div>
                    </div>
                    <div class='flex flex-1 flex-col'>
                        <img className='object-cover h-full' src={ship_loading3} alt='/' />
                    </div>
                    <div class='flex flex-1 flex-col'>
                        <img className='object-cover h-full' src={ship_loading4} alt='/' />
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Gallery