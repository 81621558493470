import React from 'react'
import Navbar from './components/Navbar'
import Home from './components/Home'
import Facility from './components/Facility'
import Product from './components/Product'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Gallery from './components/Gallery'

function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <Product />
      <Gallery />
      <Facility />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
